<div class="lg:pt-7 main">
  @if (!isMobile) {
  <div class="overflow-hidden">
    <div
      class="flex hidden lg:col-3 bg-cover bg-no-repeat bg-center w-full justify-content-end align-items-center p-0 fundo"
      style="background: url('/assets/img/bg/Homem-meia-idade-oculos.png') top"
    >
      <div class="flex align-items-center callToActionBox_home">
        <div class="w-full h-auto">
          @if(!isSubscribed){
          <div class="" style="min-height: 160px !important">
            <h1
              class="text-center title-father line-height-2 callToActionText_home"
              style="min-height: 160px !important"
            >
              Ainda não é <br />
              assinante da <br />
              Estação do Olho?
            </h1>
          </div>
          <div class="text-center">
            <p-button
              label="Assine Agora"
              [outlined]="true"
              [rounded]="true"
              severity="secondary"
              styleClass="p-button-outlined white p-button-strong w-full lg:w-auto button-banner"
              [routerLink]="['/assinatura']"
            ></p-button>
          </div>
          }@else {
          <div class="" style="min-height: 160px !important">
            <h1
              class="text-center title-father line-height-2"
              style="min-height: 160px !important"
            >
              Bem vindo à<br />
              Estação do Olho!<br />
              Sua visão importa<br />
              para nós!
            </h1>
          </div>
          }
        </div>
      </div>
    </div>
  </div>
  }
  <div class="flex lg:col-12 fundo-grid">
    <div class="grid col-12 lg:col-10 mx-auto px-0 lg:pt-6 pb-0">
      <div
        class="md:col-6 mt-7 lg:col-4 flex flex-column align-items-center lg:hidden bg-mobile-client pr-1 justify-content-end"
      >
        <div class="flex align-items-center" [style]="{ width: '90%' }">
          <div class="w-full relative flex justify-content-end">
            @if(!isSubscribed){
            <p class="text-center font-bold">
              Ainda não é<br />
              assinante da <br />
              Estação do Olho?
            </p>

            }@else {
            <p class="text-center font-bold text-xl">
              Bem vindo à<br />
              Estação do Olho!<br />
              Sua visão importa<br />
              para nós!
            </p>
            }
          </div>
        </div>
        <p-button
          label="Assine Agora"
          [outlined]="true"
          [rounded]="true"
          severity="secondary"
          styleClass="p-button-outlined p-button-strong lg:w-auto button-banner"
          [routerLink]="['/assinatura']"
          class="text-white"
        ></p-button>
      </div>

      <div class="md:col-6 lg:col-4">
        <div class="mx-auto">
          <div
            class="p-5 text-center border-round result"
            [ngStyle]="{ 'background-color': isMobile ? arrColors[0] : '' }"
          >
            <div
              class="shadow-2 border-circle bg-white p-4 inline-flex justify-content-center align-items-center mb-4"
            >
              <img
                src="/assets/img/icons/ic_resultados.svg"
                alt="icone-resultados"
              />
            </div>
            <div class="font-bold mb-4" [style]="{ color: 'white' }">
              Resultados
            </div>
            <div class="text-description mb-4 line-height-3">
              Veja os resultados dos seus exames de triagem feitos na Estação do Olho.
            </div>
            <p-button
              label="Ir para Resultados"
              [outlined]="true"
              [rounded]="true"
              severity="secondary"
              styleClass="p-button-outlined p-button-strong w-full lg:w-auto"
              [routerLink]="['/resultados']"
            ></p-button>
          </div>
        </div>
      </div>

      <div class="md:col-6 lg:col-4">
        <div class="mx-auto">
          <div
            class="p-5 text-center voucher"
            [ngStyle]="{ 'background-color': isMobile ? arrColors[1] : '' }"
          >
            <div
              class="shadow-2 border-circle bg-white p-4 inline-flex justify-content-center align-items-center mb-4"
            >
              <img
                src="/assets/img/icons/ic_vouchers.svg"
                alt="icone-vouchers"
              />
            </div>
            <div
              class="font-bold mb-4"
              [ngStyle]="{ color: isMobile ? arrColors[0] : 'white' }"
            >
              Cupons
            </div>
            <div
              class="text-description mb-4 line-height-3"
              [ngStyle]="{ color: isMobile ? arrColors[0] : 'white' }"
            >
            Adquira cupons para preços especiais em consultas médicas, pacotes de tratamento, óticas, e mais!
            </div>
            <p-button
              label="Ir para Cupons"
              [outlined]="true"
              [rounded]="true"
              severity="secondary"
              styleClass="p-button-outlined p-button-strong w-full lg:w-auto"
              routerLink="/cupons"
            ></p-button>
          </div>
        </div>
      </div>

      <div class="md:col-6 lg:col-4">
        <div class="mx-auto">
          <div
            class="p-5 text-center register"
            [ngStyle]="{ 'background-color': isMobile ? arrColors[2] : '' }"
          >
            <div
              class="shadow-2 border-circle bg-white p-4 inline-flex justify-content-center align-items-center mb-4"
            >
              <img
                src="/assets/img/icons/ic_assinatura.svg"
                alt="icone-assinaturas"
              />
            </div>
            <div
              class="font-bold mb-4"
              [ngStyle]="{ color: isMobile ? arrColors[0] : 'white' }"
            >
              Assinatura
            </div>
            <div
              class="text-description mb-4 line-height-3"
              [ngStyle]="{ color: isMobile ? arrColors[0] : 'white' }"
            >
            Entre para o nosso clube de benefícios e tenha acesso ilimitado a cupons e outras vantagens exclusivas.
            </div>
            <p-button
              label="Ir para Assinatura"
              [outlined]="true"
              [rounded]="true"
              severity="secondary"
              styleClass="p-button-outlined p-button-register w-full lg:w-auto"
              [routerLink]="['/assinatura']"
            ></p-button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <section id="parceiros" class="xl:py-8">
    <div class="col-12 xl:col-10 mx-auto">
      <h2 class="border-bottom-1 border-400 text-center xl:text-left">
        Parceiros
      </h2>
      <div class="col-10 col:lg-12 py-6 mx-auto text-center">
        <p-button
          label="Conheça nossos parceiros"
          severity="success"
          styleClass="p-button-save w-full lg:w-auto border-round-3xl"
          [routerLink]="['/parceiros']"
        ></p-button>
      </div>
    </div>
  </section>
</div>
